@mixin font-playfair-black {
    font-family: "Playfair Display";
    font-weight: 900; // Black - thickest
}

@mixin font-playfair-extra-bold {
    font-family: "Playfair Display";
    font-weight: 800; // Extra Bold
}

@mixin font-playfair-bold {
    font-family: "Playfair Display";
    font-weight: 700; // Bold
}

@mixin font-playfair-semibold {
    font-family: "Playfair Display";
    font-weight: 600; // Semibold
}

@mixin font-playfair-medium {
    font-family: "Playfair Display";
    font-weight: 500; // Medium
}

@mixin font-playfair {
    font-family: "Playfair Display";
    font-weight: 400; // Regular
}

@font-face {
    @include font-playfair-black;
    src:
        local("Playfair Display"),
        url(~_assets/fonts/Playfair_Display/static/PlayfairDisplay-Black.ttf) format("truetype");
}

@font-face {
    @include font-playfair-extra-bold;
    src:
        local("Playfair Display"),
        url(~_assets/fonts/Playfair_Display/static/PlayfairDisplay-ExtraBold.ttf) format("truetype");
}

@font-face {
    @include font-playfair-bold;
    src:
        local("Playfair Display"),
        url(~_assets/fonts/Playfair_Display/static/PlayfairDisplay-Bold.ttf) format("truetype");
}

@font-face {
    @include font-playfair-semibold;
    src:
        local("Playfair Display"),
        url(~_assets/fonts/Playfair_Display/static/PlayfairDisplay-SemiBold.ttf) format("truetype");
}

@font-face {
    @include font-playfair-medium;
    src:
        local("Playfair Display"),
        url(~_assets/fonts/Playfair_Display/static/PlayfairDisplay-Medium.ttf) format("truetype");
}

@font-face {
    @include font-playfair;
    src:
        local("Playfair Display"),
        url(~_assets/fonts/Playfair_Display/static/PlayfairDisplay-Regular.ttf) format("truetype");
}

.font-playfair-black {
    @include font-playfair-black;
}

.font-playfair-extra-bold {
    @include font-playfair-extra-bold;
}

.font-playfair-bold {
    @include font-playfair-bold;
}

.font-playfair-medium {
    @include font-playfair-medium;
}

.font-playfair-semi-bold {
    @include font-playfair-semibold;
}

.font-playfair {
    @include font-playfair;
}
