.loader {
    &.full-screen,
    &.large-screen {
        width: 100%;
        height: 100vh;
        opacity: 0.9;
        display: flex;
    }

    &.medium-screen {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img.loader-img {
            width: 32px;
            height: 32px;
            margin: auto;
            padding: 0px;
        }
    }

    &.small-screen {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        img.loader-img {
            width: 24px;
            height: 24px;
            margin: auto;
            padding: 0px;
        }
    }

    img.loader-img {
        width: 72px;
        height: 72px;
        padding: 36px;
        margin: auto;
        align-self: center;
    }
}
