@mixin font-source-sans-black {
    font-family: "Source Sans Pro";
    font-weight: 900; // Black - thickest font
}

@mixin font-source-sans-bold {
    font-family: "Source Sans Pro";
    font-weight: 700;
}

@mixin font-source-sans-semi-bold {
    font-family: "Source Sans Pro";
    font-weight: 600;
}

@mixin font-source-sans {
    // regular
    font-family: "Source Sans Pro";
    font-weight: 400;
}

@mixin font-source-sans-light {
    font-family: "Source Sans Pro";
    font-weight: 300;
}

@mixin font-source-sans-extra-light {
    font-family: "Source Sans Pro";
    font-weight: 200;
}

/**
 * SOURCE SANS PRO FONTS
*/
@font-face {
    @include font-source-sans-black;
    src:
        local("Source Sans Pro"),
        url(~_assets/fonts/Source_Sans_Pro/SourceSansPro-Black.ttf) format("truetype");
}

@font-face {
    @include font-source-sans-bold;
    src:
        local("Source Sans Pro"),
        url(~_assets/fonts/Source_Sans_Pro/SourceSansPro-Bold.ttf) format("truetype");
}

@font-face {
    @include font-source-sans-semi-bold;
    src:
        local("Source Sans Pro"),
        url(~_assets/fonts/Source_Sans_Pro/SourceSansPro-SemiBold.ttf) format("truetype");
}

@font-face {
    @include font-source-sans;
    src:
        local("Source Sans Pro"),
        url(~_assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf) format("truetype");
}

@font-face {
    @include font-source-sans-light;
    src:
        local("Source Sans Pro"),
        url(~_assets/fonts/Source_Sans_Pro/SourceSansPro-Light.ttf) format("truetype");
}

@font-face {
    @include font-source-sans-extra-light;
    src:
        local("Source Sans Pro"),
        url(~_assets/fonts/Source_Sans_Pro/SourceSansPro-ExtraLight.ttf) format("truetype");
}

@font-face {
    font-family: "SourceSansPro";
    src:
        local("SourceSansPro"),
        url(~_assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf) format("truetype");
}

.font-source-sans-black {
    @include font-source-sans-black;
}

.font-source-sans-bold {
    @include font-source-sans-bold;
}

.font-source-sans-semi-bold {
    @include font-source-sans-semi-bold;
}

.font-source-sans {
    @include font-source-sans;
}

.font-source-sans-light {
    @include font-source-sans-light;
}

.font-source-sans-extra-light {
    @include font-source-sans-extra-light;
}
