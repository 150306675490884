@import "~_style/fonts/index.scss";

@font-face {
    font-family: "GoogleSans";
    src:
        local("GoogleSans"),
        url(../_assets/fonts/Google_sans/ProductSans-Medium.ttf) format("truetype");
}

$social-btn-font: "GoogleSans";
