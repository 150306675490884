@import "~_style/font-vars.scss";

.version-update-modal {
    text-align: center;
    &.modal {
        padding-top: 0;
        .modal-content {
            width: calc(100vw - 40px);
            top: 10vh;
            max-height: 80vh;
            overflow-y: scroll;
            border-radius: 20px;

            .modal-body {
                padding: 24px;

                .main-page-header {
                    .top-right-more-options {
                        height: 24px;
                    }

                    .close-icon {
                        float: right;
                        top: 24px;
                        right: 24px;
                    }
                }

                .version-update {
                    &-title-wrapper {
                        margin-top: 0px;
                        margin-bottom: 32px;

                        .title {
                            font-size: 22px;
                            line-height: 30px;
                            margin-bottom: 8px;
                        }

                        .sub-title {
                            @include font-source-sans-semi-bold;
                            font-size: 12px;
                            line-height: 16px;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                        }
                    }

                    &-content {
                        ul {
                            padding: 0;
                            list-style-type: none;
                            text-align: left;
                            font-size: 16px;
                            line-height: 24px;

                            li {
                                margin-bottom: 16px;
                                display: table;
                                &::before {
                                    content: "⭐️";
                                    font-size: 14px;
                                    padding-right: 10px;
                                    display: table-cell;
                                }
                            }
                        }
                    }
                    &-footer {
                        margin-top: 32px;
                        p {
                            margin: 0;
                            padding: 0;
                            margin-bottom: 8px;
                            font-size: 16px;
                            line-height: 24px;
                        }

                        .logo {
                            width: 79px;
                            height: 34px;
                            margin: auto;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                    }
                }
                button {
                    margin: auto;
                }
            }
        }
    }
}
