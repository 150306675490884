@import '_style/font-vars';
@import '_style/color-vars';

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
    background-color: $background-color;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;
    -webkit-tap-highlight-color: transparent;

    text-align: center;
    font-family: -apple-system, BlinkMacSystemFont, 'SourceSansPro', 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;

    &.ios {
        top: -100px;
        margin-top: 100px;

        .ios-statusbar-hack {
            position: fixed;
            top: -100px;
            height: 100px;
            width: 100%;
            background-color: $background-color;
            transform: translate3d(0, 0, 0);
            z-index: 10000000;
            &.modal-open {
                background-color: #c4c1be;
            }
        }

        .full-screen-picture {
            background-color: black;
        }
    }
    /*
    &.go-native {
        top: 0;
        margin-top: 0;
    }
*/
    &.loading {
        overflow: hidden;
        opacity: 0.5;

        &::after {
            content: '';
            display: block;
            background-color: $background-color; /* Fallback color */
            background-color: rgba(0, 0, 0, 0.1); /* Black w/ opacity */
            background-image: url(./_assets/img/icons/loader/loader.gif);
            background-repeat: no-repeat;
            background-position: center center;
            height: 100vh;
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 20;
        }
    }
}

.no-scrollbar {
    // Hiding scrollbar on all browsers
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
        display: none;
    }
    // end of hiding scrollbar
}
.update-notification-wrapper {
    position: fixed;
    top: -100px;
    width: 100%;
    left: 0;
    width: 100vw;
    z-index: 30;
    transition: top 0.5s;
    -moz-transition: top 0.5s;
    -webkit-transition: top 0.5s;
    -o-transition: top 0.5s;

    &.show {
        top: -10px !important;
    }

    .update-notification-content {
        padding: 15px;
        padding-top: 25px;
        background-color: $background-color;
        box-shadow:
            0 4px 8px 0 rgba(0, 0, 0, 0.1),
            0 6px 20px 0 rgba(0, 0, 0, 0.19);
        -webkit-animation-duration: 0.4s;

        animation-duration: 0.4s;
        border-radius: 15px;

        &.animate-slide-up-300px {
            animation-name: animate-slide-up-300px;
            -webkit-animation-name: animate-slide-up-300px;
        }
    }
}

.library-common-category-page {
    &.sticky-header {
        .main-page-header {
            margin-bottom: 0;
            padding: 16px 0 0;
        }
    }
}

/* Add Animation */
@-webkit-keyframes animate-slide-up-300px {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}
@keyframes animate-slide-up-300px {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0;
        opacity: 1;
    }
}

@-webkit-keyframes animate-slide-down-300px {
    from {
        top: 0;
        opacity: 1;
    }
    to {
        top: -300px;
        opacity: 0;
    }
}
@keyframes animate-slide-down-300px {
    from {
        top: 0;
        opacity: 1;
    }
    to {
        top: -300px;
        opacity: 0;
    }
}

body,
input,
button,
.MuiInputBase-input {
    @include font-source-sans;
}
button {
    background-color: $whispering-red;
    color: white;
}

h2,
h3,
h4,
h5 {
    @include font-playfair;
}

h1 {
    font-family: $heading-font;
    padding: 16px;
    margin: 0 auto;
    font-weight: 400;
}

.form-control {
    width: 75%;
    margin: 0 auto 15px !important;
}
.MuiFormControl-root.fc-form-control {
    label.MuiFormLabel-root {
        @include font-source-sans;
    }
    button.kan-button {
        margin-top: 10px;
        width: 100%;
    }
}
.auth-section {
    max-width: 500px;
    margin: 0 auto;

    h1 {
        font-weight: 400;
        line-height: 40px;
        font-size: 32px;
    }
}

.center-text {
    text-align: center !important;

    p.MuiFormHelperText-root {
        text-align: center !important;
    }
}

a,
.a-div {
    cursor: pointer;
    text-decoration: none;
    color: $black;
}

.grid-icons {
    width: 90%;
    max-width: 500px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;

    .grid-item {
        cursor: pointer;
        width: 30%;
        padding: 1.666%;

        a {
            text-decoration: none;
        }

        .grid-item-icon-wrapper {
            width: 70px;
            height: 70px;
            margin: auto;
            background-color: $icon-bg;
            border-radius: 50%;
            text-align: center;
            display: grid;

            img.grid-icon {
                width: 70%;
                margin: auto;
            }
        }

        .grid-icon-label {
            text-align: center;
            font-size: 12px;
            color: $black;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 80px;
            margin: auto;
            display: flex;
            justify-content: center;
            line-height: 16px;

            &-name {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                max-width: 65px;
            }

            &-count {
                margin-left: 2px;
            }
        }
    }
}

.selectable-icons-wrapper {
    display: flex;
    flex-wrap: wrap;

    .selectable-icon-item-wrapper {
        width: 70px;
        height: 70px;
        margin: 5px auto;
        background-color: $desert-sand;
        border-radius: 50%;
        text-align: center;
        display: grid;

        img {
            width: 70%;
            margin: auto;
        }
    }
}

.invisible-input {
    border: none;
    outline: none !important;
    background: transparent;
    font-size: 16px;
    box-shadow: 0 0 0 rgb(255, 255, 255);

    &:focus-visible {
        border: none;
        outline: 0px solid transparent;
    }
}

.notebook-alike-area {
    white-space: pre-wrap;
    min-height: 100px;
    padding: 0px 10px;
    height: auto;
    color: #000000;
    display: inline-block;
    vertical-align: bottom;
    font-size: 16px;
    line-height: 30px;
    margin: 0 auto;
    padding-bottom: 10px;
    resize: none;
    min-height: 30px;
    width: 90%;
    background-image: -moz-linear-gradient(
        top,
        transparent,
        transparent 29px,
        #cfcecd 0px
    );

    background-image: -webkit-linear-gradient(
        top,
        transparent,
        transparent 29px,
        #cfcecd 0
    );

    -webkit-background-size: 100% 30px;
    background-size: 100% 30px;
}

[contenteditable] {
    outline: none !important;
    &[data-placeholder]:empty:before {
        content: attr(data-placeholder);
        color: #888;
    }
}

.round-icon-wrapper {
    width: 60px;
    height: 60px;
    margin: 5px auto;
    background-color: #e8dbcf;
    border-radius: 50%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 80%;
    }
}

p.MuiFormHelperText-root.Mui-error,
p.error {
    color: #f44336;
    font-size: 0.75rem;
    display: block;
}

.MuiButton-root {
    text-transform: none !important;
    letter-spacing: 0 !important;
}

.modal {
    background-color: $background-color;
}

form {
    h4 {
        margin-bottom: 5px;
    }
}

.MuiButton-text.MuiButton-textPrimary {
    color: $black;
}
