@import '~_style/color-vars';

/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 10000; /* Sit on top */
    padding-top: 5vh; /* Location of the box */
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.2); /* Black w/ opacity */

    &.opened {
        display: block;
    }

    h2,
    h3 {
        margin: 10px;
    }

    h2.title {
        flex-grow: 1;
    }

    /* Modal Content */
    .modal-content {
        position: relative;
        background-color: $background-color;
        margin: auto;
        padding: 0;
        height: 90vh;
        width: 90vw;
        max-width: 500px;
        overflow: hidden;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1),
            0 6px 20px 0 rgba(0, 0, 0, 0.11);
        -webkit-animation-duration: 0.4s;

        animation-duration: 0.4s;
        border-radius: 15px;

        .back-button {
            margin-left: 10px;
        }

        .top-right-more-options {
            margin-right: 10px;
        }

        &.animate-slide-up-300px {
            animation-name: animate-slide-up-300px;
            -webkit-animation-name: animate-slide-up-300px;
        }

        &.animate-slide-down-300px {
            animation-name: animate-slide-down-300px;
            -webkit-animation-name: animate-slide-down-300px;
        }

        &.animate-right {
            animation-name: animate-right;
            -webkit-animation-name: animate-right;
        }

        &.animate-top {
            animation-name: animatetop;
            -webkit-animation-name: animatetop;
        }
        &.animate-left {
            animation-name: animate-left;
            -webkit-animation-name: animate-left;
        }

        &.animate-top-25 {
            animation-name: animatetop-25;
            -webkit-animation-name: animatetop-25;
        }

        &.animate-bottom-menu-dropup {
            animation-name: animate-bottom-menu-dropup;
            -webkit-animation-name: animate-bottom-menu-dropup;
        }

        &.animate-bottom-menu-dropup-120px {
            animation-name: animate-bottom-menu-dropup-120px;
            -webkit-animation-name: animate-bottom-menu-dropup-120px;
        }

        &.animate-bottom-menu-dropup-300px {
            animation-name: animate-bottom-menu-dropup-300px;
            -webkit-animation-name: animate-bottom-menu-dropup-300px;
        }

        &.animate-bottom-menu-dropup-422px {
            animation-name: animate-bottom-menu-dropup-422px;
            -webkit-animation-name: animate-bottom-menu-dropup-422px;
        }

        .modal-header {
            padding: 2px 16px;
            background-color: #e8dbcf;
            color: $black;
        }

        .modal-body {
            padding: 30px;
            margin-top: 10px;
            padding-top: 16px;
            height: 100%;

            .main-page-header {
                position: unset;
                padding-top: unset;

                &.only-close-button {
                    display: flex;
                    justify-content: flex-end;
                }

                .top-right-more-options {
                    .close-icon {
                        width: 24px;
                        height: 24px;

                        img {
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .modal-footer {
            height: 40px;
            width: 100%;
            padding: 5px 0;
            background-image: linear-gradient(transparent, #f5f1ee);
            color: $black;
            position: fixed;
            bottom: 0;
            left: 0;
        }
    }

    &.auto-height {
        .modal-content {
            height: auto;
            max-height: 95vh;
            overflow-y: scroll;
        }
    }

    &.full-width-modal {
        padding-top: 0;

        .modal-content {
            height: 100vh;
            width: 100vw;
            max-height: fit-content;
            border-radius: 0;

            .modal-body {
                padding: 20px;
            }
        }
    }

    &.terms-modal {
        padding-top: 0;

        .modal-content {
            width: 100%;
            height: 100%;

            .modal-body {
                padding: 0;

                button {
                    z-index: 2;
                }
            }
        }
    }
}

@keyframes animate-left {
    from {
        right: 0vw;
        opacity: 1;
    }
    to {
        right: -100vw;
        opacity: 0;
    }
}

@keyframes animate-right {
    from {
        right: -100vw;
        opacity: 0;
    }
    to {
        right: 0;
        opacity: 1;
    }
}

@-webkit-keyframes animatetop {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 0%;
        opacity: 1;
    }
}

@keyframes animatetop {
    from {
        top: -400px;
        opacity: 0;
    }
    to {
        top: 0%;
        opacity: 1;
    }
}

@-webkit-keyframes animatetop-25 {
    from {
        top: -400px;
        opacity: 0;
    }
    to {
        top: 25%;
        opacity: 1;
    }
}

@keyframes animatetop-25 {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 25%;
        opacity: 1;
    }
}

@-webkit-keyframes animatetop-120px {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 120px;
        opacity: 1;
    }
}

@keyframes animatetop-120px {
    from {
        top: -300px;
        opacity: 0;
    }
    to {
        top: 120px;
        opacity: 1;
    }
}

/* Add Animation */
@-webkit-keyframes animate-bottom-menu-dropup {
    from {
        height: 0px;
        opacity: 0;
    }
    to {
        height: 35vh;
        opacity: 1;
    }
}

@keyframes animate-bottom-menu-dropup {
    from {
        height: 0px;
        opacity: 0;
    }
    to {
        height: 35vh;
        opacity: 1;
    }
}

/* Add Animation */
@-webkit-keyframes animate-bottom-menu-dropup-300px {
    from {
        height: 0px;
        opacity: 0;
    }
    to {
        height: 300px;
        opacity: 1;
    }
}

@keyframes animate-bottom-menu-dropup-300px {
    from {
        height: 0px;
        opacity: 0;
    }
    to {
        height: 300px;
        opacity: 1;
    }
}

@-webkit-keyframes animate-bottom-menu-dropup-422px {
    from {
        height: 0px;
        opacity: 0;
    }
    to {
        height: 422px;
        opacity: 1;
    }
}

@keyframes animate-bottom-menu-dropup-422px {
    from {
        height: 0px;
        opacity: 0;
    }
    to {
        height: 422px;
        opacity: 1;
    }
}

/* Add Animation */
@-webkit-keyframes animate-bottom-menu-dropup-120px {
    from {
        height: 0px;
        opacity: 0;
    }
    to {
        height: 120px;
        opacity: 1;
    }
}

@keyframes animate-bottom-menu-dropup-120px {
    from {
        height: 0px;
        opacity: 0;
    }
    to {
        height: 120px;
        opacity: 1;
    }
}
