@import '~_style/color-vars.scss';

/**
* We remove any underline that might be present on buttons within links
*/
a button.kan-button {
    text-decoration: none;
    color: $black;
}

button.kan-button {
    cursor: pointer;
    border-radius: 10px;
    border: none;
    padding: 10px 25px;
    font-size: 16px;
    margin: 5px;
    position: relative;
    width: 80%;

    &.large-btn {
        height: 50px;
        padding-top: 0;
        padding-bottom: 0;
    }

    &.small-btn {
        height: 36px;
        padding-top: 0;
        padding-bottom: 0;
    }

    &:disabled {
        opacity: 0.6;
    }
    &.delete {
        background: transparent;
        color: $black;
        border: 1px solid #c0c0c0;
        margin-top: 0px;
    }

    &.secondary-btn {
        background: transparent;
        color: $antique-brass;
        border: 1px solid $antique-brass;
        margin-top: 0px;
    }
    .button-content {
        margin: auto;
    }

    img {
        height: 20px;
        width: auto;
    }
}
form {
    button.kan-button {
        width: 100%;
    }
}
