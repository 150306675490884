@mixin font-made-bruno-regular {
    font-family: 'Made Bruno';
    font-weight: 400;
}

@font-face {
    @include font-made-bruno-regular;
    src: local('Made Bruno'),
        url(~_assets/fonts/Made_Bruno/made_bruno-webfont.woff)
            format('truetype');
}

$heading-font: 'Made Bruno';
